import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { useSelector } from 'react-redux';
import Supercluster from 'supercluster';

import { ApiLiveStop, Coordinates } from '~/api/types';

import {
    useIntermodalFeatures,
    useHereMaps,
    useIsolatedRoutes,
    useLiveDispatchTasks,
    useLiveDispatchUnassignedTasksV2,
    useMapUtils,
    useOnDemandDispatchMarkerEventHandler
} from '~/hooks';
import { DEFAULT_PAGINATION_OPTIONS } from '~/hooks/useLiveDispatchTasks';
import { useSelectedMapRoutes } from '~/components/MapPage/useSelectedMapRoutes';
import makeUnassignedClusterMarkers from '~/utils/map-modes/unassigned-tasks-cluster-mode';
import makeEquipmentClusterMarkers from '~/utils/map-modes/equipment-cluster-mode';

import { usePlanMapPropsContext } from '~/components/MapPage/PlanMap/PlanMapPropsContext';
import { usePlanMapEventsContext } from '~/components/MapPage/PlanMap/PlanMapEventsContext';

import { selectDateOnly } from '~/reducers/selectedDateSlice';
import { selectDispatchedDrivers } from '~/reducers/liveDriversSlice';
import { selectSelectedDrawerCardData } from '~/reducers/selectedDrawerCardDataSlice';
import { selectSelectedDrawerCardId } from '~/reducers/selectedDrawerCardIdSlice';
import { selectIsOpenSuggestDrawer } from '~/reducers/mapDrawerSettingsSlice';
import { selectSelectedTaskIds } from '~/reducers/selectedTaskIdsSlice';

import makeDispatchedComponents from '~/utils/map-modes/dispatched-routes-mode';
import { makeLiveRoutesComponents } from '~/utils/map-modes/live-routes-stops-mode';
import { selectEquipment } from '~/reducers/equipmentSlice';
import {
    selectShowUnassignedTasks,
    selectIsClusteringEquipment,
    selectIsClusteringStops,
    selectedIsClusteringUnassignedTasks
} from '~/reducers/mapSettingsSlice';
import { selectLastPlanMapZoom } from '~/reducers/lastPlanMapZoomSlice';
import { selectLastPlanMapBounds } from '~/reducers/lastPlanMapBoundsSlice';
import { makeUnassignedStopMarkerEffects } from '../makeUnassignedStopMarkerEffects';
import { ConfigurableMapRouteMode } from '~/reducers/mapSettingsSlice/types';
import { makeEquipmentMarkerEffects } from '../makeEquipmentMarkerEffects';
import { isEmpty } from 'lodash';
import { getFilteredEquipments } from '~/components/MapPage/PlanMap/utils';
import { useEquipmentTableStates } from '~/components/MapPageDrawers/LiveDispatchDrawer/useEquipmentTableStates';
import { selectTasksV2ApiPaginationOptions } from '~/reducers/tasksV2Slice';
import { selectMainClient } from '~/reducers/mainClientSlice';

interface UseDispatchedModeMapMarkersProps {
    stopLevelCoordinatesRef: MutableRefObject<Coordinates[]>;
    driverCoordinatesRef: MutableRefObject<Coordinates[]>;
    unassignedSuperClusters: Supercluster.AnyProps[];
    equipmentSuperClusters: Supercluster.AnyProps[];
    liveStopsSuperClusters: Supercluster.AnyProps[];
}

interface UseDispatchedModeMapMarkersReturnValues {
    routeMarkers: JSX.Element[];
    routeLines: JSX.Element[];
    selectedRouteLines: JSX.Element[];
    routeStopMarkers: JSX.Element[];
    depotStopMarkers: JSX.Element[];
    routeMarkerCoordinates: Coordinates[];
    equipmentMarkers: JSX.Element[];
}

// @TODO break down further https://wisesys.atlassian.net/browse/RP-846
const useDispatchedModeMapMarkers = ({
    stopLevelCoordinatesRef,
    driverCoordinatesRef,
    unassignedSuperClusters,
    equipmentSuperClusters,
    liveStopsSuperClusters
}: UseDispatchedModeMapMarkersProps): UseDispatchedModeMapMarkersReturnValues => {
    // @TODO type PlanMapPropsContext https://wisesys.atlassian.net/browse/RP-840
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { mapInstance, routesLevelData } = usePlanMapPropsContext();
    const { mapRouteMode, isDispatchedRouteMode } = useMapUtils();
    const { emittedEventHandler } = usePlanMapEventsContext();
    const { selectedMapRoutes } = useSelectedMapRoutes({
        planRoutes: routesLevelData
    });
    const { viewCardDetails } = useIsolatedRoutes();
    const onDemandDispatchMarkerEventHandler =
        useOnDemandDispatchMarkerEventHandler();
    const showUnassignedTasks = useSelector(
        selectShowUnassignedTasks(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const isClusteringUnassignedTasks = useSelector(
        selectedIsClusteringUnassignedTasks(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const isClusteringEquipment = useSelector(
        selectIsClusteringEquipment(
            mapRouteMode as unknown as ConfigurableMapRouteMode
        )
    );
    const isClusteringStops = useSelector(
        selectIsClusteringStops(mapRouteMode as ConfigurableMapRouteMode)
    );
    const { isHerePlanMapActive } = useHereMaps();
    const lastPlanMapZoom = useSelector(selectLastPlanMapZoom);
    const lastPlanMapBounds = useSelector(selectLastPlanMapBounds);
    const mainClient = useSelector(selectMainClient);
    const selectedDate = useSelector(selectDateOnly);
    const dispatchedDrivers = useSelector(selectDispatchedDrivers);
    const equipment = useSelector(selectEquipment);

    const { globalFilter, columnFilters } = useEquipmentTableStates();

    const { driverPrivacy } = mainClient?.preferences || {};

    const { data: selectedDrawerCard } = (useSelector(
        selectSelectedDrawerCardData
    ) ?? {}) as {
        data?: {
            cepLocation: Coordinates;
            schedule: ApiLiveStop[];
        };
    };
    const selectedDrawerRouteId = useSelector(selectSelectedDrawerCardId);
    const selectedTaskIds = useSelector(selectSelectedTaskIds);
    const isOpenSuggestDrawer = useSelector(selectIsOpenSuggestDrawer);

    const tasksV2ApiPaginationOptions = useSelector(
        selectTasksV2ApiPaginationOptions
    );

    const [routeMarkers, setRouteMarkers] = useState<JSX.Element[]>([]);
    const [routeLines, setRouteLines] = useState<JSX.Element[]>([]);
    const [routeMarkerCoordinates, setRouteMarkerCoordinates] = useState<
        Coordinates[]
    >([]);
    const [routeStopMarkers, setRouteStopMarkers] = useState<JSX.Element[]>([]);
    const [depotStopMarkers, setDepotStopMarkers] = useState<JSX.Element[]>([]);
    const [selectedRouteLines, setSelectedRouteLines] = useState<JSX.Element[]>(
        []
    );
    const [equipmentMarkers, setEquipmentMarkers] = useState<JSX.Element[]>([]);

    const { enableLiveDispatch } = useIntermodalFeatures();

    const { unassignedTasks: filteredUnassignedTasks } = useLiveDispatchTasks({
        selectedTaskIds,
        apiPaginationOptions: DEFAULT_PAGINATION_OPTIONS
    });

    const { unassignedTasks: filteredUnassignedTasksV2 } =
        useLiveDispatchUnassignedTasksV2({
            selectedTaskIds,
            apiPaginationOptions: tasksV2ApiPaginationOptions
        });

    const { isRouteMultiSelectInProgress } = useIsolatedRoutes();

    const unassignedTasks = enableLiveDispatch
        ? filteredUnassignedTasksV2
        : filteredUnassignedTasks;

    const getClusters = useCallback(
        (superCluster) => {
            if (isHerePlanMapActive) {
                return superCluster.points;
            }
            return superCluster.getClusters(lastPlanMapBounds, lastPlanMapZoom);
        },
        [isHerePlanMapActive, lastPlanMapBounds, lastPlanMapZoom]
    );

    useEffect(() => {
        if (!selectedDate) return;
        setRouteMarkers([]);
        setRouteLines([]);
        setRouteMarkerCoordinates([]);
        setRouteStopMarkers([]);
        setSelectedRouteLines([]);
        setEquipmentMarkers([]);
    }, [selectedDate]);

    const unassignedTasksMarkerEffects = useMemo(
        () =>
            makeUnassignedStopMarkerEffects({
                isClusteringStops: isClusteringUnassignedTasks,
                unassignedSuperClusters,
                getClusters,
                makeUnassignedClusterMarkers,
                emittedEventHandler,
                planTasks: unassignedTasks
            }),
        [
            isClusteringUnassignedTasks,
            unassignedSuperClusters,
            getClusters,
            emittedEventHandler,
            unassignedTasks
        ]
    );

    useEffect(() => {
        if (!isDispatchedRouteMode) {
            return;
        }
        let unassignedTaskMarkers: JSX.Element[] = [];
        let unassignedTasksCoords: Coordinates[] = [];

        stopLevelCoordinatesRef.current = [];

        const {
            driverMarkers: newDriverMarkers,
            routeLines: newRouteLines,
            driverMarkerCoordinates
        } = makeDispatchedComponents(dispatchedDrivers, mapInstance);

        driverCoordinatesRef.current = driverMarkerCoordinates;
        setRouteMarkers(newDriverMarkers as unknown as JSX.Element[]);
        setRouteLines(newRouteLines as unknown as JSX.Element[]);
        setRouteMarkerCoordinates(driverMarkerCoordinates);

        if (isRouteMultiSelectInProgress) {
            return;
        }

        const selectedClientDriverIds = selectedDrawerCard
            ? [selectedDrawerRouteId]
            : selectedMapRoutes;

        if (showUnassignedTasks) {
            unassignedTaskMarkers = unassignedTasksMarkerEffects;
            const flattenedUnassignedTasksMarkerEffects =
                unassignedTasksMarkerEffects.flat(Infinity);
            unassignedTasksCoords = flattenedUnassignedTasksMarkerEffects.map(
                (marker: JSX.Element) => {
                    const { props } = marker;
                    const { lat, lng } = props;
                    return { lat, lng };
                }
            );
        }

        const {
            liveRoutesDepotMarkers,
            liveRoutesStopMarkers,
            liveRoutesIsolatedRouteLines
        } = makeLiveRoutesComponents({
            selectedClientDriverIds,
            dispatchedDrivers,
            showDrawerOnDemandDispatch: false,
            onDemandDispatchTasks: unassignedTasks,
            onDemandDispatchMarkerEventHandler,
            mapInstance,
            mapRouteMode,
            isOpenSuggestDrawer,
            emittedEventHandler,
            liveStopsSuperClusters,
            getClusters,
            isClusteringStops,
            driverPrivacy
        });

        const liveRoutesMarkers = [
            ...liveRoutesStopMarkers,
            ...unassignedTaskMarkers
        ];
        const liveRoutesLines =
            showUnassignedTasks && !enableLiveDispatch
                ? []
                : liveRoutesIsolatedRouteLines;
        setDepotStopMarkers(liveRoutesDepotMarkers);
        setRouteStopMarkers(liveRoutesMarkers);
        setSelectedRouteLines(liveRoutesLines);

        const selectedDriverStopCoords = selectedClientDriverIds.reduce<
            Coordinates[]
        >((aggregator, clientDriverId) => {
            if (clientDriverId) {
                const [, driverId] = clientDriverId.split('_');
                const driver = dispatchedDrivers.find(
                    ({ id }) => id === driverId
                );
                if (driver) {
                    driver.schedule.forEach((stop: ApiLiveStop) => {
                        aggregator.push(stop.location.location);
                    });
                }
            }
            return aggregator;
        }, []);
        if (selectedDrawerCard) {
            selectedDriverStopCoords.push(selectedDrawerCard.cepLocation);
        }
        stopLevelCoordinatesRef.current = [
            ...selectedDriverStopCoords,
            ...unassignedTasksCoords
        ];
    }, [
        emittedEventHandler,
        dispatchedDrivers,
        mapRouteMode,
        isDispatchedRouteMode,
        selectedDrawerCard,
        selectedDrawerRouteId,
        selectedMapRoutes,
        viewCardDetails,
        showUnassignedTasks,
        unassignedTasks,
        mapInstance,
        getClusters,
        isClusteringStops,
        enableLiveDispatch,
        driverCoordinatesRef,
        isOpenSuggestDrawer,
        liveStopsSuperClusters,
        stopLevelCoordinatesRef,
        unassignedTasksMarkerEffects,
        onDemandDispatchMarkerEventHandler,
        isRouteMultiSelectInProgress,
        driverPrivacy
    ]);

    useEffect(() => {
        if (!isDispatchedRouteMode || !mapInstance || isEmpty(equipment)) {
            return;
        }

        const filteredEquipments = getFilteredEquipments({
            equipment,
            globalFilter,
            columnFilters
        });

        const equipmentMarkerEffects = makeEquipmentMarkerEffects({
            isClusteringStops: isClusteringEquipment,
            equipmentSuperClusters,
            getClusters,
            makeEquipmentClusterMarkers,
            equipment: filteredEquipments
        });

        setEquipmentMarkers(equipmentMarkerEffects as unknown as JSX.Element[]);
    }, [
        isDispatchedRouteMode,
        mapInstance,
        equipment,
        emittedEventHandler,
        equipmentSuperClusters,
        getClusters,
        isClusteringEquipment,
        globalFilter,
        columnFilters
    ]);

    return {
        routeMarkers,
        depotStopMarkers,
        routeStopMarkers,
        routeLines,
        selectedRouteLines,
        routeMarkerCoordinates,
        equipmentMarkers
    };
};

export default useDispatchedModeMapMarkers;
